import request from '@/tools/request';

export const getDataList = loading => {
  return request({
    url: '/termgenerator-config-list.html',
    loading,
    loadingTime: 500,
  });
};

export const syncData = (params, loading) => {
  return request({
    url: '/termgenerator-config-sync.html',
    params,
    loading,
  });
};

export const getPreviewUrl = () => {
  return request({
    url: '/termgenerator-config-preview.html',
  });
};

export const getLookOver = (id, loading) => {
  return request({
    url: '/termgenerator-config-show.html',
    params: { id },
    loading,
  });
};
