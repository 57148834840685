<template>
  <div class="term-list">
    <header>
      <m-page-header title="条款设置"></m-page-header>
    </header>
    <main>
      <div class="control-bar">
        <div class="search-wrap"></div>
        <div class="btn-wrap">
          <el-button @click="preview">预览</el-button>
          <router-link :to="getRoute('term-list-config')" class="config-link-wrap">
            <el-button type="primary">配置基础信息</el-button>
          </router-link>
        </div>
      </div>
      <div class="list-wrap">
        <m-table
          :data="dataList"
          :loading="listLoading"
          @selection-change="changeSelection"
          :control="[
            {
              text: '批量同步',
              on: 'batchSync',
              loading: batchSyncLoading,
            },
          ]"
          @batchSync="syncTerm(selectionIds, selectionRow, true)"
          ref="mTable"
        >
          <el-table-column type="selection"> </el-table-column>
          <el-table-column prop="base_name" label="名称"> </el-table-column>
          <el-table-column label="上次同步时间">
            <template #default="tableItem">
              {{ tableItem.row.sync_time || '—' }}
            </template>
          </el-table-column>
          <el-table-column label="同步状态">
            <template #default="tableItem">
              <el-tag size="small" v-if="tableItem.row.sync_status == 0">未同步</el-tag>
              <el-tag type="success" size="small" v-else-if="tableItem.row.sync_status == 1">
                已同步
              </el-tag>
              <el-tag type="danger" size="small" v-else-if="tableItem.row.sync_status == 2">
                同步失败
              </el-tag>
              <el-tooltip
                class="item"
                effect="light"
                :content="tableItem.row.sync_msg"
                placement="right"
                v-if="tableItem.row.sync_status == 2"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="220px">
            <template #default="tableItem">
              <el-button
                size="small"
                @click="syncTerm(tableItem.row.id, tableItem.row)"
                :loading="tableItem.row.loading"
                >同步</el-button
              >
              <el-button size="small" @click="lookOver(tableItem.row.id, tableItem.row.base_name)">
                查看
              </el-button>
            </template>
          </el-table-column>
        </m-table>
      </div>

      <el-dialog
        :title="popupTitle"
        v-model="isShowPopup"
        width="550px"
        @close="closeDialog"
        top="150px"
        :lock-scroll="false"
      >
        <template v-if="!hasShowText">
          <div
            v-html="curShowText"
            class="global-scroll-box browser-style-box"
            v-if="curShowText"
          ></div>
          <div style="min-height: 200px; height: 300px" v-loading="true" v-if="!curShowText"></div>
        </template>
        <div
          style="min-height: 200px; height: 300px; padding-top: 20px; box-sizing: border-box"
          v-else
        >
          <el-result icon="warning" title="提示" :subTitle="hasShowText"></el-result>
        </div>
      </el-dialog>
    </main>
  </div>
</template>

<script>
import { ref, toRefs } from 'vue';
import { ElMessage, ElNotification } from 'element-plus';

import MPageHeader from '@/components/pageHeader/PageHeader.vue';
import MTable from '@/components/table/Table';
import { getRoute } from '../../../tools/config';
import { getDataList, getLookOver, getPreviewUrl, syncData } from '../api/termList';

export default {
  components: {
    'm-page-header': MPageHeader,
    'm-table': MTable,
  },
  setup() {
    // 初始化 列表数据
    const dataList = ref([]);
    const listLoading = ref(false);
    const getTableData = async () => {
      const res = await getDataList(listLoading);

      // 向 data 的所有元素中传入 loadding，用于设置 同步 按钮的loading状态
      dataList.value = res.data;
    };

    const batchSyncLoading = ref(false);
    const mTable = ref();
    // 同步
    const syncTerm = async (ids, row) => {
      // 批量同步的时候提示必须择
      if (Array.isArray(row) && !row.length) {
        ElMessage({
          type: 'warning',
          center: true,
          message: '请选择需要批量同步的选项',
        });
        return;
      }

      if (!Array.isArray(row)) {
        row = [row];
      }

      // 所有正在同步的行都应该是 loading 状态
      row.forEach(item => {
        item.loading = true;
      });

      const res = await syncData({ ids }, batchSyncLoading);

      getTableData();
      // 由于重新加载了列表，需要手动关闭 MTable 组件的 control-bar
      mTable.value.closeControlBar();

      row.forEach(item => {
        item.loading = undefined;
      });

      const { data } = res;

      if (!data) {
        return;
      }

      let successCount = 0;
      let errorCount = 0;
      data.forEach(item => {
        // 统计 成功和失败的条数
        if (item.status === 1) {
          successCount++;
        } else {
          errorCount++;
        }
        row.forEach(rowItem => {
          if (rowItem.base_name === item.base_name) {
            rowItem.sync_status = item.status;
            rowItem.sync_msg = item.msg;
          }
        });
      });

      ElNotification({
        title: '同步完成',
        message: `成功 ${successCount} 条，失败 ${errorCount} 条`,
        type: 'success',
      });
    };

    const selectionIds = ref([]);
    const selectionRow = ref([]);
    // 选择复选框时
    const changeSelection = val => {
      selectionIds.value = val
        .map(item => {
          return item.id;
        })
        .join(',');
      selectionRow.value = val;
    };

    const curShowText = ref('');
    const isShowPopup = ref(false);
    const hasShowText = ref();
    const popupTitle = ref('');
    // 打开弹窗并写入内容
    const lookOver = async (id, termName) => {
      // 打开弹窗
      isShowPopup.value = true;
      hasShowText.value = null;
      popupTitle.value = termName;

      const { data, status, msg } = await getLookOver(id, toRefs(false));
      if (!status) {
        hasShowText.value = msg;
      }
      curShowText.value = data;
    };
    // 关闭弹窗
    const closeDialog = () => {
      setTimeout(() => {
        curShowText.value = '';
      }, 300);
    };

    // 预览
    const preview = async () => {
      const res = await getPreviewUrl();
      open(res.data);
    };

    return {
      dataList,
      getTableData,
      listLoading,
      getRoute,
      syncTerm,
      changeSelection,
      selectionRow,
      selectionIds,
      lookOver,
      batchSyncLoading,
      curShowText,
      isShowPopup,
      closeDialog,
      hasShowText,
      popupTitle,
      preview,
      mTable,
      test(cur) {
        console.log('cur', cur);
      },
    };
  },
  mounted() {
    this.getTableData();
  },
};
</script>

<style lang="scss" scoped>
main {
  padding: 0 20px;
  .control-bar {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;

    .search-wrap {
      width: 260px;
    }
    .btn-wrap {
      .el-button {
        min-width: 120px;
      }
      .config-link-wrap {
        margin-left: 10px;
      }
    }
  }
  .list-wrap {
    .el-button {
      min-width: 80px;
    }
    .el-icon-question {
      font-size: 16px;
      cursor: pointer;
      margin-left: 10px;
    }
  }
  .global-scroll-box {
    height: 300px;
  }
}
</style>
